<template>
  <div id="app">
    <div
      class="d-flex justify-content-center"
      style="align-items: center; height: 100vh"
      v-if="!isReady"
    >
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <router-view v-if="isReady" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { auth, database } from '@/firebase';
import _ from 'lodash';

export default {
  data() {
    return {
      isReady: false,
      eventId: 'C0UhSACTRIYdayqD7rFz',
      accountId: 'rqPbvpK7wEHmEQ8QuyqS',
    };
  },
  computed: {
    ...mapGetters({
      eventCurrentPath: 'event/currentPath',
      currentPeople: 'people/current',
      currentAccount: 'account/current',
      currentEvent: 'event/current',
    }),
  },
  methods: {
    ...mapActions({
      setCurrentPeople: 'people/setCurrent',
      getPeople: 'people/get',
      clearPeople: 'people/clearItems',
      setCurrentEvent: 'event/setCurrent',
      getEvent: 'event/get',
      getAccount: 'account/get',
      setCurrentAccount: 'account/setCurrent',
      findAgenda: 'agenda/find',
      clearAgendaState: 'agenda/clearItems',
      setCurrentStage: 'stage/setCurrent',
    }),
  },
  async created() {
    const fbUser = await auth().currentUser;
    let userRef = null;

    if (this.currentPeople) {
      // eğer user yoksa realtime database'de kaydı offline yap
      console.log('make offline');
      userRef = database().ref(`/attendees/${this.currentEvent.id}/${this.currentPeople.id}`);
      userRef.update({ status: 'offline' });
    }

    if (!fbUser) {
      console.log('created: no user');
      // console.log(localStorage.getItem('t'));
      if (localStorage.getItem('t')) {
        // console.log('singin from local storage');
        await auth()
          .signInWithCustomToken(localStorage.getItem('t'))
          .then(() => {
            console.log('created: signed in');
          })
          .catch((err) => {
            console.log('created: signed in error');
            console.log(err);
          });
      } else {
        console.log('push to login');
        this.$router.push('/login');
      }
    } else if (this.$router.currentRoute.name !== 'Home') {
      console.log('has user');
      console.log('push to home');
      this.$router.push('/');
    }

    // account'u al
    const account = await this.getAccount({
      path: 'account',
      id: this.accountId,
    });

    this.setCurrentAccount(account);

    // event'i al
    const event = await this.getEvent({
      path: `account/${this.currentAccount.id}/event`,
      id: this.eventId,
    });
    this.setCurrentEvent(event);

    // auth status dinleme
    auth().onAuthStateChanged(async (user) => {
      if (!user) {
        console.log('changed: no user');
        if (this.currentPeople) {
          console.log('make offline');
          const userStatusDatabaseRef = database().ref(
            `/attendees/${this.currentEvent.id}/${this.currentPeople.id}`,
          );

          // eğer user yoksa realtime database'de kaydı offline yap
          await userStatusDatabaseRef.update({ status: 'offline' });
          this.setCurrentPeople(null);
          this.isReady = true;
        }
        if (this.$router.currentRoute.name !== 'Login') {
          window.location = '/login';
        }
      } else {
        let id;

        // id token claim'lerinden gerekli verileri al
        await user.getIdTokenResult().then((result) => {
          id = result.claims.id;
        });

        // user'ı firestore/invitations'dan al
        const people = await this.getPeople({
          path: `account/${this.currentAccount.id}/event/${this.currentEvent.id}/invitations`,
          id,
        });

        console.log('set people');
        await this.setCurrentPeople(people);
        this.isReady = true;

        // database'de eventId/userId'ye işaret eden kayda referans al
        const userStatusDatabaseRef = database().ref(
          `/attendees/${this.currentEvent.id}/${this.currentPeople.id}`,
        );

        /*
        const attendee = null;
        await userStatusDatabaseRef.once('value', (snapshot) => {
          if (snapshot.val()) {
            attendee = snapshot.val();
          }
        });
        */
        const currentPeople = _.pickBy(this.currentPeople, (v) => v !== undefined);
        const { password, ...restOf } = currentPeople;

        database()
          .ref('.info/connected')
          .on('value', (snapshot) => {
            if (snapshot.val() === false) {
              return;
            }

            // disconnect durumunda bu kaydın status'unu offline yap
            userStatusDatabaseRef
              .onDisconnect()
              .update({ status: 'offline', lastLogout: database.ServerValue.TIMESTAMP, ...restOf })
              .then(() => {
                // status'ü online yap
                console.log('ONLINE');
                const payload = {
                  status: 'online',
                  lastLogin: database.ServerValue.TIMESTAMP,
                  ...restOf,
                };
                userStatusDatabaseRef.once('value').then((sn) => {
                  if (!sn.exists()) {
                    payload.firstLogin = database.ServerValue.TIMESTAMP;
                  }
                  userStatusDatabaseRef.update(payload);
                });
                // this.$router.push('/');
                /*
                if (this.$router.currentRoute.name !== 'Home') {
                }
                */
              });
          });
      }
    });
  },
};
</script>

<style lang="scss"></style>

<template>
  <div class="login-wrapper">
    <div id="login-page" class="container">
      <section class="login d-flex align-items-center justify-content-center">
        <div id="login-box">
          <form id="login-form" class="form" @submit.prevent="login">
            <h3 class="text-center mb-4">Giriş Yap</h3>
            <div class="form-group">
              <input
                type="text"
                placeholder="E-posta"
                name="email"
                id="username"
                class="form-control"
                v-model="email"
              />
            </div>
            <div class="form-group">
              <input
                type="password"
                placeholder="Parola"
                name="password"
                id="password"
                class="form-control"
                v-model="password"
              />
            </div>
            <div class="form-group">
              <label for="remember-me">
                <span>
                  <input id="remember-me" name="remember-me" type="checkbox" />
                </span>
                <span>
                  <a
                    href="https://livecast.box.com/s/ac72r34ztbxk7pmh9dpqfl9z22m3ap49"
                    target="_blank"
                  >
                    KVKK ve Aydınlatma metinlerini
                  </a>
                  okudum. Kabul ediyorum.</span
                >
              </label>
            </div>
            <b-button
              type="submit"
              name="submit"
              class=""
              block
              size="lg"
              variant="primary"
              v-if="!loginPending"
              >Giriş</b-button
            >
            <b-button
              variant="primary"
              class="btn-md mt-3"
              disabled
              block
              size="lg"
              v-if="loginPending"
            >
              <b-spinner small></b-spinner>
              Giriş yapılıyor...
            </b-button>

            <div class="alert alert-light text-center" role="alert">
              {{ error }}
            </div>
          </form>
          <b-button v-b-modal.forgetPassword variant="link"
            >Şifremi Unuttum</b-button
          >
        </div>
      </section>
    </div>
    <b-modal
      id="forgetPassword"
      title="Şifre Hatırlatma"
      ok-title="Tamam"
      okOnly
    >
      <b-form-input
        v-model="forgetEmail"
        placeholder="Lütfen mail adresinizi giriniz"
      ></b-form-input>
      <p class="mt-3 ml-3">{{ forgetMessage }}</p>
      <b-button
        variant="primary"
        class="mt-2"
        @click="getPassword()"
        v-if="!passLoading"
      >
        <span>Şifre alın</span>
      </b-button>
      <b-button variant="primary" class="mt-2" v-if="passLoading">
        <b-spinner small class="mr-2"></b-spinner>
        <span>Şifreniz getiriliyor</span>
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
// import Hero from '@/components/Hero.vue';
import { mapGetters, mapActions } from 'vuex';
import { auth, database, db } from '../firebase';

export default {
  data() {
    return {
      email: '',
      password: '',
      eventId: 'C0UhSACTRIYdayqD7rFz',
      accountId: 'rqPbvpK7wEHmEQ8QuyqS',
      error: '',
      loginPending: false,
      forgetEmail: null,
      forgetMessage: null,
      passLoading: false,
    };
  },
  components: {
    // Hero,
  },
  computed: {
    ...mapGetters({
      eventCurrentPath: 'event/currentPath',
      accountCurrentPath: 'account/currentPath',
      currentPeople: 'people/current',
      currentAccount: 'account/current',
      currentEvent: 'event/current',
    }),
  },
  methods: {
    ...mapActions({
      setCurrentEvent: 'event/setCurrent',
      getEvent: 'event/get',
      getAccount: 'account/get',
      setCurrentAccount: 'account/setCurrent',
    }),
    async login() {
      this.loginPending = true;
      try {
        // token elde etmek için backend'e request yolla
        const tokenResponse = await axios({
          method: 'post',
          url: `${process.env.VUE_APP_BASE_URL}/login`,
          data: {
            email: this.email,
            password: this.password,
            event: this.eventId,
          },
        });
        const { token } = tokenResponse.data;

        // gelen token'la firebase auth'a giriş yap
        console.log('login with new token');
        await auth().signInWithCustomToken(token);
        localStorage.setItem('t', token);
        this.loginPending = false;
        this.$router.push('/');
      } catch (error) {
        console.log({ error });

        if (error.response) {
          if (error.response.status !== 500) {
            this.error = error.response.data.message;
          } else {
            this.error = 'Bilinmeyen bir hata oluştu';
          }
        } else {
          this.error = 'Bilinmeyen bir hata oluştu';
        }
        this.loginPending = false;
      }
    },
    async getPassword() {
      this.passLoading = true;
      if (!this.forgetEmail) {
        this.forgetMessage = 'Lütfen bir email adresi giriniz';
      }
      db.collection(`${this.accountCurrentPath}/event/${this.eventId}/invitations`)
        .where('email', '==', this.forgetEmail)
        .get()
        .then((querySnapshot) => {
          this.passLoading = false;
          if (querySnapshot.empty) {
            this.forgetMessage = 'Email adresi kayıtlı değil.';
            return;
          }
          querySnapshot.forEach((doc) => {
            const people = doc.data();
            this.forgetMessage = `Şifreniz: ${people.password}`;
          });
          // doc.data() is never undefined for query doc snapshots
        });
    },
  },
  async created() {
    console.log(this.currentPeople);
    const account = await this.getAccount({
      path: 'account',
      id: this.accountId,
    });

    this.setCurrentAccount(account);
    if (this.currentPeople) {
      console.log('login: make offline');
      const userStatusDatabaseRef = database().ref(
        `/attendees/${this.currentEvent.id}/${this.currentPeople.id}`,
      );

      // eğer user yoksa realtime database'de kaydı offline yap
      userStatusDatabaseRef.update({ status: 'offline' });
    } else {
      console.log('no people');
    }
  },
};
</script>

<style lang="scss" scoped>
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.1em;
  vertical-align: middle;
}
.login-wrapper {
  background-image: url("../assets/images/rader_slider-1920x1080-01.png");
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  background-position-x: center;
  background-position-y: top;
}
#login-page {
  margin-top: 90px;
}

.alert-light {
  color: #b60016;
}
</style>
